import React, { useState } from 'react'
import Layout from '../../components/layout'
import HubspotForm from 'react-hubspot-form'
import Slider from "react-slick";
import Helmet from "react-helmet"
import ClientsModule from '../../components/Homepage2023Content/ClientsModule'

const VantageGifts = ({ location }) => {

    const [imageIndex, setimageIndex] = useState(0)

      const imageObject = {
        giftcard: 'https://res.cloudinary.com/vantagecircle/image/upload/v1657697155/gatsbycms/uploads/2022/11/Group-21.webp',
        uniqueexp: 'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-14.webp',
        variety: 'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-9-Copy-2.webp',
        serviceawards: 'https://res.cloudinary.com/vantagecircle/image/upload/v1657697155/gatsbycms/uploads/2022/11/Group-10-Copy-2.webp',
        joiningkits: 'https://res.cloudinary.com/vantagecircle/image/upload/v1657697155/gatsbycms/uploads/2022/11/GC-Merchandise.webp'
      }

      const images = [imageObject.giftcard, imageObject.uniqueexp, imageObject.variety, imageObject.serviceawards, imageObject.joiningkits]

      const NextArrow = ({ onClick }) => (
        <div className='arrow next' onClick={onClick}>
            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/carousel-arrow-right.webp' loading = "lazy" height= "100" width= "100" className='cursor-pointer' />
        </div>
      )
      const PrevArrow = ({ onClick }) => (
        <div className='arrow prev' onClick={onClick}>
            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/carousel-arrow-left.webp' loading = "lazy" height= "100" width= "100" className='cursor-pointer' />
        </div>
      )


    const settings = {
        dots: false,
        lazyload: true, 
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow/>,
        beforeChange: (current, next) => setimageIndex(next),
        autoplay: true,
        autoplaySpeed: 2000
    };

  return (
    <>
        <Layout location={location}>
        <Helmet>
                <title>Vantage Gifts | Vantage Circle</title>

                <meta name="description" content="Gift your employees thoughtful and customized gifts from our global collection & celebrate every occasion with equal zeal, turning moments into everlasting memories." />
                <meta property="twitter:title" content="Vantage Gifts | Vantage Circle" />
                        
                <meta property="twitter:description" content="Gift your employees thoughtful and customized gifts from our global collection & celebrate every occasion with equal zeal, turning moments into everlasting memories." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/services/vantage-gifts/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Vantage Gifts | Vantage Circle" />

                <meta property="og:description" content="Gift your employees thoughtful and customized gifts from our global collection & celebrate every occasion with equal zeal, turning moments into everlasting memories." />
                <link rel="canonical" href="https://www.vantagecircle.com/services/vantage-gifts/"></link>
            </Helmet>

            {/* <section className='bg-purple-lightpurple w-full relative' style={{ zIndex: '9'}}>
                <div className='mx-auto w-full xl:max-w-7xl'>
                    <div className='w-full pt-4 xl:pt-9'><h2 className='font-bold text-purple-100 text-4xl xl:text-7xl py-5 text-center py-9 xl:w-10/12 mx-auto'>Corporate <span class="relative">Gifting<img class="absolute -bottom-2 right-0 md:right-0 xl:-right-1" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" alt="hero-stroke" /></span> options that will make your employees go wow!</h2></div>
                    <p class="text-gray-light text-lg xl:text-2xl px-3 text-center pb-5 w-10/12 xl:max-w-3xl mx-auto" style = {{ lineHeight: '2.3rem'}}>Delight your employees on every occasion & make them feel valued with a customizable all-in-one corporate gifting solution.</p>
                    <div className='flex justify-center'><a href='#FormVR' className='vc-btn-orange-v2 w-9/12 md:w-1/3 xl:w-3/12 py-4'>Let's get started</a></div>
                </div>
                <div className='h-28 md:h-52 lg:h-60 xl:h-72 2xl:h-96'></div>
                <div className='absolute -bottom-5 md:-bottom-16 xl:-bottom-20 2xl:-bottom-24 left-0' style= {{ zIndex: '-1' }}><img class="mt-9 xl:mt-0" loading='lazy' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/CG-Hero-IMG-2-scaled.webp" /></div>
                <div className='absolute xl:left-20 2xl:left-60 xl:top-24 2xl:top-40 spin hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "65" width= "65" />
                </div>
                <div className='absolute xl:left-40 2xl:left-80 xl:bottom-72 2xl:bottom-80 spin hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "40" width= "40" />
                </div>
                <div className='absolute xl:right-24 2xl:right-72 xl:top-10 2xl:top-10 spin4 hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "40" width= "40" />
                </div>
                <div className='absolute xl:right-40 2xl:right-80 xl:bottom-72 2xl:bottom-80 spin hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "65" width= "65" />
                </div>
            </section> */}

            {/* <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
                <div class="max-w-7xl grid xl:grid-cols-2 justify-center items-start z-10 lg:px-3 relative">
                    <div class="w-full md:w-10/12 xl:w-full mx-auto flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                            <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full bg-indigo-50">
                                <h2 class="text-purple-100 text-sm lato">Corporate Gifting</h2>
                            </div>
                            <h1 class="text-purple-100 lg:pr-3 xl:w-10/12 text-4xl md:text-4xl xl:text-5xl font-bold text-center xl:text-left"><span class="text-orange">Celebrate every<br/> occasion</span> that matters to your employees.</h1>
                            <p class="my-8 text-md xl:text-xl text-purple-100 text-center xl:text-left w-full">A unique offering to ensure you bring in a variety of options to our employees.This helps in creating tailor-made rewards solution for your employees and significantly enhances their experience.</p>
                            <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/2 cursor-pointer my-8">
                                <a className="vc-new-orange-btn-rounded-full lato mb-3 xl:mb-0 xl:mr-2" href="#form">Book a demo</a>
                                <a className="vc-new-white-border-btn-rounded-full lato rounded-full mt-3 xl:mt-0 xl:ml-2" href="#msTeamVideo">Watch Video</a>
                            </div>      
                        </div>
                    <div class="xl:w-5/12 h-80 md:h-96 xl:h-80"></div>
                    <div class="absolute -bottom-72 md:scale-90 transform xl:scale-100 md:right-28 lg:right-1/4 xl:-bottom-16 xl:right-0 flex justify-center items-center h-full mt-6 xl:mt-0 lg:ml-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Hero-IMG.png" alt="Vantage Rewards" width="600" /></div>
                </div>
            </section> */}

            <section className='bg-purple-lightpurple w-full relative' style={{ zIndex: '9'}}>
                <div className='mx-auto w-full xl:max-w-7xl'>
                    <div className='w-full pt-4 xl:pt-9'><h2 className='font-bold text-purple-100 text-4xl xl:text-7xl py-5 text-center py-9 xl:w-10/12 mx-auto'><span className='text-orange'>Celebrate</span> every occasion that matters to your employees.</h2></div>
                    <p class="text-gray-light text-lg xl:text-2xl px-3 text-center pb-5 w-10/12 xl:max-w-3xl mx-auto" style = {{ lineHeight: '2.3rem'}}>A unique offering to ensure you bring in a variety of options to your employees. This helps create tailor-made rewards solution for your employees and significantly enhances their experience.</p>
                    <div className='flex justify-center px-4'><a href='#FormVR' className='vc-new-orange-btn-rounded-full w-9/12 md:w-1/3 xl:w-3/12 py-4'>Let's get started</a></div>
                </div>
                <div className='h-28 md:h-52 lg:h-60 xl:h-72 2xl:h-96'></div>
                <div className='absolute -bottom-5 md:-bottom-16 xl:-bottom-20 2xl:-bottom-24 left-0' style= {{ zIndex: '-1' }}><img class="mt-9 xl:mt-0" loading='lazy' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/CG-Hero-IMG-2-scaled.webp" /></div>
                <div className='absolute xl:left-20 2xl:left-60 xl:top-24 2xl:top-40 hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "65" width= "65" />
                </div>
                <div className='absolute xl:left-40 2xl:left-80 xl:bottom-72 2xl:bottom-80 hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "40" width= "40" />
                </div>
                <div className='absolute xl:right-24 2xl:right-72 xl:top-10 2xl:top-10  hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "40" width= "40" />
                </div>
                <div className='absolute xl:right-40 2xl:right-80 xl:bottom-72 2xl:bottom-80 hidden xl:flex'>
                    <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Star-1.webp" height = "65" width= "65" />
                </div>
            </section>
            <section className='bg-2 w-full py-10 xl:py-16 px-3'>
                <h2 className='font-bold homepage-heading text-center py-3 xl:py-20 max-w-7xl xl:max-w-5xl 2xl:max-w-4xl mx-auto'>Strengthen your workplace connections with <span className='text-orange'>thoughtful gifts</span></h2>
                <div className='grid grid-cols-1 xl:grid-cols-4 gap-10 justify-center items-center max-w-7xl mx-auto py-8 px-3 xl:px-0'>
                    <div className='rounded-lg shadow flex flex-col items-center justify-center bg-gray-100 border-2 border-white py-16 px-6 h-96'>
                        <img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/CG-connect.webp' height="90" width="90" />
                        <p className='pt-9 text-xl text-center text-gray-500'>Connect with employees who have been instrumental in your organization's success</p>
                    </div>
                    <div className='rounded-lg shadow flex flex-col items-center justify-center bg-gray-100 border-2 border-white py-16 px-6 h-96'>
                        <img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-50.webp' height="90" width="90" />
                        <p className='pt-9 text-xl text-center text-gray-500'>Reconnect with your existing employees to instill a sense of belonging</p>
                    </div>
                    <div className='rounded-lg shadow flex flex-col items-center justify-center bg-gray-100 border-2 border-white py-16 px-6 h-96'>
                        <img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-11.webp' height="90" width="90" />
                        <p className='pt-9 text-xl text-center text-gray-500'>Show gratitude to those who have gone the extra mile to accomplish the impossible</p>
                    </div>
                    <div className='rounded-lg shadow flex flex-col items-center justify-center bg-gray-100 border-2 border-white py-16 px-6 h-96'>
                        <img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/CG-memory.webp' height="90" width="90" />
                        <p className='pt-9 text-xl text-center text-gray-500'>Deliver a delightful experience to those who have been an integral part of the journey</p>
                    </div>
                </div>
            </section>
            <section className='w-full h-auto xl:flex flex-col justify-center py-10 xl:py-16 rewards-bg-1 hidden'>
            <h2 class="text-3xl xl:text-5xl max-w-3xl text-center text-gray-250 mb-4 xl:mb-8 px-1 mx-auto"><span class="text-orange">One-stop solution</span> for all your corporate gifting needs</h2>
                <div className='max-w-7xl mx-auto'>
                <Slider {...settings}>
                    {images.map((img, idx) => (
                        <div className={idx === imageIndex ? 'slide activeSlide' : 'slide'}>
                            <img src = {img} alt={img} className='mx-auto size' />
                        </div>
                    ))}
                </Slider>
                </div>
            </section>
            <section class="flex w-full place-content-center py-10 xl:py-16 bg-white overflow-x-hidden px-3 lg:px-0">
    <div class="flex flex-col place-items-center max-w-7xl px-3">
        {/* <h2 class="bannerSectionTitle-2 text-gray-250 my-8 px-1"><span class="relative">Features<img class="absolute -bottom-1 right-0 xl:right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span> that meet your needs.</h2>
        <p class="text-lg xl:text-xl my-1 text-gray-250 text-center">Onboard a scalable, customizable, cost-effective platform<br/> to meet your organization’s goal</p> */}
        <div class="w-full flex flex-col justify-center items-center">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-sart w-full mb-0 lg:mb-8 relative">
                <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-center z-10">
                    <h2 class="text-gray-250 rewardsSectionTitle-all-left my-3">Celebrate every occasion in the perfect way with <span className='text-orange'>gift cards</span></h2>
                    <p class="text-gray-250 text-left text-lg xl:text-xl my-3 leading-8">Amplify the collective joy in your workplace by offering your employees gift cards ranging from a wide variety of local-to-global brands that suit every occasion.</p>
                </div>
                <div class="w-full flex justify-center items-center z-10">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-16.webp" />
                </div>
                {/* <div class="z-0 hidden lg:block absolute -left-24 lg:-top-28 xl:-top-20 2xl:top-0">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-1.png" />
                </div> */}
            </div>
            <div class="flex flex-col lg:flex-row-reverse justify-center items-start w-full mt-6 mb-0 lg:my-12 relative">
                <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                    <h2 class="text-gray-250 rewardsSectionTitle-all-left my-3">Gift your employees a <span className= 'text-orange'>once-in-a-lifetime </span>experience</h2>
                    <p class="text-gray-250 text-left text-lg xl:text-xl my-3 leading-8">Give your employees a whole new redemption option to break the daily mundane & live the perfect work-life balance with relaxing and luxurious hotel stays, trekking and camping in nature’s lap, budget-friendly holiday packages & so much more.</p>
                </div>
                <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-17.webp" />
                </div>
                {/* <div class="z-0 hidden lg:block absolute -right-24 bottom-0">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-2.png" />
                </div> */}
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mt-6 mb-0 lg:mt-12 relative">
                <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                    <h2 class="text-gray-250 rewardsSectionTitle-all-left my-3">Offer your employees a variety of <span className='text-orange'>merchandise</span></h2>
                    <p class="text-gray-250 text-left text-lg xl:text-xl my-3 leading-8">Give your employees the flexibility to redeem gift points for merchandise of their choice, ranging from home essentials to work necessities and beyond, across top global brands, and have it delivered to their door in no time.</p>
                </div>
                <div class="w-full flex justify-center items-center z-10">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697155/gatsbycms/uploads/2022/11/Group-18.webp" width="620" height="635" />
                </div>
                {/* <div class="z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-2 2xl:left-20 2xl:-bottom-10">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-3.png" />
                </div> */}
            </div>
            {/* <div class="flex flex-col lg:flex-row-reverse justify-center items-start w-full mt-6 mb-0 lg:my-12 relative">
                <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                    <h2 class="text-gray-250 rewardsSectionTitle my-3">Celebrate milestone with <span className='text-orange'>long service awards</span></h2>
                    <p class="text-gray-250 text-lg xl:text-xl my-3 leading-8">Appreciate your employees’ unwavering loyalty & boost your employee retention rate by celebrating service milestones with customizable long service awards.</p>
                </div>
                <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1657706836/gatsbycms/uploads/2022/11/Group-19.webp" />
                </div>
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-ful my-12 relative">
                <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                    <h2 class="text-gray-250 rewardsSectionTitle my-3">Create an unforgettable first impression with customizable<span className='text-orange'> joining kits</span></h2>
                    <p class="text-gray-250 text-lg xl:text-xl my-3 leading-8">Create a sense of belonging, make new hires feel at home & instill a personal touch in a professional space with customizable and budget-friendly joining kits.</p>
                </div>
                <div class="w-full flex justify-center items-center z-10">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/GC-Merchandise-2.webp" />
                </div>
                <div class="z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-16 2xl:-left-28">
                    <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-5.png" />
                </div>
            </div> */}
        </div>
    </div>
</section>
<section className='w-full bg-gray-100 py-10 xl:py-16'>
    <h2 class="homepage-heading text-center text-gray-250 mb-4 px-1 mx-auto">We are<span class="text-orange"> loved</span> by companies worldwide</h2>
    <div className='grid xl:grid-cols-2 mx-auto pt-10 max-w-5xl px-3 justify-center gap-9'>
        <div>
            <div className='grid' style={{ gridTemplateColumns: '1fr 10fr' }}>
                <div className='mx-auto'><img loading="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height="30" width="30" /></div>
                <div>
                    <p className = "m-0 text-gray-500 text-xl xl:text-2xl pl-2" >Cost-efficient gifting solutions</p>
                    <p className='pl-2 ml-0 py-2'>Wide range of cost-effective gifting options to suit your company's budget.</p>
                </div>
            </div>    
        </div>
        <div>
            <div className='grid' style={{ gridTemplateColumns: '1fr 10fr' }}>
                <div className='mx-auto'><img loading="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height="30" width="30" /></div>
                <div>
                    <p className = "m-0 text-gray-500 text-xl xl:text-2xl pl-2" >Shipping to more than 100+ countries</p>
                    <p className='pl-2 ml-0 py-2'>Enjoy a quick & hassle-free shipping experience in more than 100+ countries.</p>
                </div>
            </div>    
        </div>
        <div>
            <div className='grid' style={{ gridTemplateColumns: '1fr 10fr' }}>
                <div className='mx-auto'><img loading="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height="30" width="30" /></div>
                <div>
                    <p className = "m-0 text-gray-500 text-xl xl:text-2xl pl-2" >Customizable gifting options</p>
                    <p className='pl-2 ml-0 py-2'>Variety of gifting options that can be customized to fit your company's needs.</p>
                </div>
            </div>    
        </div>
        <div>
            <div className='grid' style={{ gridTemplateColumns: '1fr 10fr' }}>
                <div className='mx-auto'><img loading="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height="30" width="30" /></div>
                <div>
                    <p className = "m-0 text-gray-500 text-xl xl:text-2xl pl-2" >Customized packaging</p>
                    <p className='pl-2 ml-0 py-2'>Unique custom packaging options for a better employee experience.</p>
                </div>
            </div>    
        </div>
        <div>
            <div className='grid' style={{ gridTemplateColumns: '1fr 10fr' }}>
                <div className='mx-auto'><img loading="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height="30" width="30" /></div>
                <div>
                    <p className = "m-0 text-gray-500 text-xl xl:text-2xl pl-2" >Best-in-class customer support</p>
                    <p className='pl-2 ml-0 py-2'>Reliable customer service that values customer's time rather than just meeting expectations.</p>
                </div>
            </div>    
        </div>
        {/* <div>
            <div className='grid' style={{ gridTemplateColumns: '1fr 10fr' }}>
                <div className='mx-auto'><img loading="lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height="30" width="30" /></div>
                <div>
                    <p className = "m-0 text-gray-500 text-xl xl:text-2xl pl-2" >Best-in-class customer support</p>
                    <p className='pl-2 ml-0 py-2'>Reliable customer service that values customer's time rather than just meeting expectations.</p>
                </div>
            </div>    
        </div> */}
    </div>
</section>
<section className='py-10 xl:py-16' id='clientsSection'></section>
<section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-16 2xl:py-20 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
    <div class="lg:flex max-w-7xl px-3">
        <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3 flex flex-col xl:justify-center">
            <h2 class="text-3xl xl:text-5xl text-center lg:text-left text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0">Please tell us more about your requirements.</h2>
            <div className='flex justify-center lg:justify-end lg:w-11/12 2xl:w-full xl:pr-5 2xl:pr-10 transform rotate-90 lg:rotate-0'><img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-2-2.webp' height="110" width="110" /></div>
            {/* <ul class="list-inside orangebullets text-gray-250 lato text-lg xl:text-xl md:text-md 2xl:text-lg md:px-12 lg:px-0 w-full lg:w-8/12">
                <li class="py-1">Are you experiencing a lack of engagement and motivation among your employees?</li>
                <li class="py-1">Is toxic culture something you are trying to avoid?</li>
                <li class="py-1">No way for your workforce to be rewarded and recognized socially in real-time?</li>
                <li class="py-1">Is there no meaningful social interaction among employee peer groups?</li>
                <li class="py-1">Do you already have a rewards and recognition program, but it is not centralized globally?</li>
                <li class="py-1">Do you find it challenging to reward your dispersed workforce that is diverse and multi-lingual?</li>
            </ul> */}
        </div>
        <div class="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2" id= "form">
            <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from making your employees happy!</h2>
            <div class="w-full flex justify-center items-center lato team">
                <div class = "flex justify-center items-center">
                    <HubspotForm
                    portalId='6153052'
                    formId='109aff53-a8c6-42e6-a5c8-123d9bf4b970'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                    />
                </div>
            </div>
            <a href="/privacy-policy/" class="text-gray-250 underline lato text-sm">Read our Privacy Policy</a>               
        </div>
    </div>
</section>
<section className='w-full py-10 xl:py-16 px-6'>
            <div className='xl:flex justify-center items-center xl:items-center overflow-hidden p-6 xl:px-10 aireGradient  h-computer xl:h-80 rounded-xl max-w-7xl mx-auto'>
                <div className='w-full'>
                    <h2 className='text-purple-100 text-2xl md:text-4xl text-center xl:text-left py-5'>Explore our <span className= "text-orange">products</span></h2>
                    <ul class="list-inside grid grid-cols-1 md:grid-cols-2 text-gray-250 lato text-lg xl:text-xl md:text-md 2xl:text-lg md:px-12 lg:px-0 w-full lg:w-8/12 ml-2 xl:ml-3">
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Rewards</li>
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Fit</li>
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Pulse</li>
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Perks</li>
                    </ul>
                    {/* <p className='text-center text-purple-100 xl:text-left'>Vantage Circle's decade of experience and learnings led us to build the AIR<sup>e</sup> Structure, a new framework and strategy for employee recognition with <span className='text-orange'>Appreciation(A)</span> <span className='text-red'>Incentivization(I)</span> and <span className= "text-darkgreen">Reinforcement(R)</span> as the key themes of recognition, while <span className= "font-bold">eMotional Connect(e)</span> as the driving force.</p> */}
                    <a className='vc-new-orange-btn-rounded-full rounded-xl w-7/12 md:w-4/12 mx-auto xl:mx-0 xl:w-1/2 my-5' href= "/request-demo/" target="_blank">Request a demo</a>
                </div>
                <div className='w-full xl:flex xl:justify-end'>
                    <div className=''>
                        <img className='transform translate-x-2 mt-0 xl:mt-0 scale-100 md:scale-90 xl:scale-90 md:-translate-y-16 xl:translate-y-0 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/PRODUCT_BANNER.png' width= "420" loading='lazy' />
                    </div>       
                </div>
            </div>
        </section>
        </Layout>
    </>
  )
}

export default VantageGifts