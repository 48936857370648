import React,{useState, useEffect} from 'react'
import ReactDOM from "react-dom";
import Trans from '../Trans';

const ClientsModule = () => {
    // const [imglink, setimglink] = useState('')
    // const [width, setwidth] = useState('')
    // const [height, setheight] = useState('')
    // useEffect(() => {
    //   if(window != 'undefined'){
    //     if(window.innerWidth > 1024){
    //         setimglink("https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/Web-Client-logos-1-1.webp")
    //         setwidth('1000')
    //     }
    //     else if(window.innerWidth > 767){
    //         setimglink("https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/Web-Client-logos-1-1.webp")
    //         setwidth('600')
    //         setheight("278")
    //     }
    //     else{
    //         setimglink("https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/Mobile-Client-logos-1-1.webp")
    //         setwidth("275")
    //         setheight("446")
    //     }
    //   }
    

    // }, [])
    
  return (
    <>
        <div className='w-full justify-center items-center flex flex-col pb-3'>
            <h2 className='text-gray-250 lg:max-w-2xl xl:max-w-6xl mx-auto mb-0 text-center homepage-section-heading px-3'><span className='text-orange'>700+ </span> <Trans>companies, from SMEs to Fortune 500, trust us!</Trans></h2>
        </div>
        <div className='w-full justify-center items-center flex py-9'>
            {/* <img src= {imglink} width= {width} height= {height} /> */}
            <picture>
                <source loading="lazy"  srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1500/q_auto/gatsbycms/uploads/2023/12/vc-clients-strip-v2.webp" media="(min-width: 1200px)" width="1000" />

                <source  loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/12/vc-clients-strip-v2.webp" media="(min-width: 640px)" width="600" />

                <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_275/q_auto/gatsbycms/uploads/2023/12/vc-clients-strip-v2-mobile.webp" alt="A description of the image." width="275" />        
            </picture>
        </div>
        <div className='w-full flex justify-center'><a target="_blank" href='/corporates/' className='text-newOrange my-5 text-center vc-new-orange-border-btn-rounded-full'><Trans>See all clients</Trans></a></div>
    </>
  )
}

const renderClientsModule=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#clientsSection")
        if (id != null){
            ReactDOM.render(
                <ClientsModule />,
                id
            )
        }
    }
}

setTimeout(renderClientsModule,100)

export default ClientsModule